<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <h4>学习交流</h4>

    <footer>
      <a href="https://beian.miit.gov.cn/">ICP:京ICP备15058397号-2</a>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import U from 'uu-console'
import { No101 } from 'uu-console/dist/theme/theme.esm'

export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },
  created() {
    console.log('No101', No101)
    U.useTheme('No301').log('Hi', 'UU', 'Console');
  }
};
</script>

<style scoped>
h4 {
  font-size: 30px;
  text-align: center;
  color: #555;
}
footer {
  position: absolute;
  bottom: 10%;
  width: 100%;
  height: 30px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
footer a {
  text-decoration: none;
}
</style>